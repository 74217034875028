import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Card,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleEnChange } from "../../features/languageSlice";
import { store } from "../../app/store";
import { useSelector } from "react-redux/es/hooks/useSelector";
export default function Nav() {
  const dispatch = useDispatch();
  const [openNav, setOpenNav] = React.useState(false);
  const state = store.getState();
  const { en } = useSelector((state) => state.language);
  console.log("EN:",en)
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  console.log()
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <div className="text-red font-medium text-lg">
        <span className="flex items-center">
          <Link to="/practice-areas">Practice Areas</Link>
        </span>
      </div>
      <div className="text-red font-medium text-lg">
        <span className="flex items-center">
          <Link to="/contact">Contact</Link>
        </span>
      </div>

      <div className="text-red font-medium text-lg">
        <span className="flex items-center">
          <Link to="/">Home</Link>
        </span>
      </div>
      <div className="text-red text-lg font-bold hover:text-gray-300">
        <span className="flex items-center">
          <button
            onClick={() => dispatch(handleEnChange())}
            className="text-red"
          >
            {!en ? <>English</> : <>Tiếng Việt</>}
          </button>
        </span>
      </div>
    </ul>
  );

  return (
    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4 bg-opacity-50 border-none">
        <div className="flex items-center justify-between text-red">
          <div className="text-3xl font-bold">
            LUAT SU BACHLAN, LLC
            <div className="text-sm">
              Phone: (904) 859-7539 | Fax: (904) 458-8281
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>

            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>
    </>
  );
}
